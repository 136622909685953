import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ChartData,
  BarElement,
  BarController,
  LinearScale,
  Title,
} from 'chart.js';
import { UserGroup } from './types';
import { capitalize } from '../../../lib/capitalize';

ChartJS.register(BarController, BarElement, LinearScale, Title);
dayjs.locale('es');

interface ClientsGraphProps {
  clients?: Array<UserGroup>;
}

export default function ClientsGraph({ clients = [] }: ClientsGraphProps) {
  const [data, setData] = React.useState<ChartData<'bar'>>(null);
  React.useEffect(
    function syncClientData() {
      setData({
        labels: clients?.map((client) =>
          capitalize(
            dayjs(
              new Date(
                Number(client?._id?.year),
                Number(client?._id?.month) - 1,
                1
              )
            ).format('MMM YY')
          )
        ),
        datasets: [
          {
            label: 'Cliente',
            backgroundColor: '#4c51bf',
            borderColor: '#4c51bf',
            data: clients?.map((client) => Number(client?.count)),
            barThickness: 8,
          },
        ],
      });
    },
    [clients]
  );
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg border bg-white text-slate-700 shadow-sm">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold">
              registro de
            </h6>
            <h2 className="text-gray-800 text-xl font-semibold">Clientes</h2>
          </div>
        </div>
      </div>
      <div className="p-4 flex-auto">
        {/* Chart */}
        <div className="relative" style={{ height: '350px' }}>
          {data ? (
            <Bar
              data={data}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                hover: {
                  mode: 'nearest',
                  intersect: true,
                },
                plugins: {
                  title: {
                    display: false,
                    text: 'Orders Chart',
                  },
                  tooltip: {
                    mode: 'index',
                    intersect: false,
                  },
                  legend: {
                    labels: {
                      color: 'rgba(0,0,0,.4)',
                    },
                    align: 'end',
                    position: 'bottom',
                  },
                },
                scales: {
                  x: {
                    display: false,
                    title: {
                      display: true,
                      text: 'Mes',
                    },
                    border: {
                      dash: [2],
                      dashOffset: 2,
                    },
                    grid: {
                      color: 'rgba(33, 37, 41, 0.3)',
                      lineWidth: 2,
                    },
                  },

                  y: {
                    display: true,
                    title: {
                      display: false,
                      text: '# Clientes',
                    },
                    border: {
                      dash: [2],
                      dashOffset: 2,
                      display: false,
                    },
                    grid: {
                      color: 'rgba(33, 37, 41, 0.2)',
                    },
                  },
                },
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
