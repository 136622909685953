import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ChartData,
  PieController,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
} from 'chart.js';
import { TransactionGroup } from './types';

ChartJS.register(
  PieController,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale
);

interface TransactionGraphProps {
  transactions?: Array<TransactionGroup>;
  date?: Date;
  setDate?: React.Dispatch<React.SetStateAction<Date>>;
}

export const TRANSACTIONS_TYPE = {
  QR_CODE_SCAN: 'QR',
  QUIZ_ATTEMPT: 'Curso',
  EXCHANGE_BENEFIT: 'Beneficios',
  CUSTOM_POINTS: 'Personalizados',
  POINTS_VOLUME: 'Volumen',
  POINTS_ACCOUNT_RECEIVABLE: 'Cuentas por Cobrar',
  POINTS_AVAILABILITY: 'Disponibilidad',
  BIRTH_DAY: 'Cumpleaños',
};

function dynamicColors(i: number, total: number): string {
  const r = 84 + (i * 155) / total;
  const g = 109 + (i * 255) / total;
  const b = 193 + (i * 255) / total;
  return `rgb(${r}, ${g}, ${b})`;
}

export default function TransactionGraph({
  transactions = [],
  date,
  setDate,
}: TransactionGraphProps) {
  const [data, setData] = React.useState<ChartData<'pie'>>(null);
  React.useEffect(
    function syncTransactionData() {
      const length = transactions?.length ?? 1;
      setData({
        datasets: [
          {
            data:
              transactions?.map((transaction) =>
                Number(transaction?.percentage)
              ) ?? [],
            label: 'Tipo de transacción',
            backgroundColor:
              transactions?.map((_, i) => dynamicColors(i, length)) ?? [],
          },
        ],
        labels: (transactions?.map(
          (transaction) => TRANSACTIONS_TYPE[transaction?._id]
        ) ?? []) as string[],
      });
    },
    [transactions]
  );
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg border bg-white text-slate-700 shadow-sm">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold">
              Distribución de las
            </h6>
            <h2 className="text-gray-800 text-xl font-semibold">
              Transacciones del mes
            </h2>
          </div>
          <div className="">
            <DatePicker
              className="border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5 mt-1 block w-full disabled:bg-gray-100 disabled:cursor-not-allowed text-gray-700 bg-gray-200"
              selected={date}
              dateFormat="dd/MM/yyyy"
              onChange={(_date: Date) => setDate(_date)}
            />
          </div>
        </div>
      </div>
      <div className="p-4 flex-auto">
        {/* Chart */}
        <div className="relative" style={{ height: '350px' }}>
          {data ? (
            <Pie
              data={data}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  tooltip: {
                    callbacks: {
                      title(tooltipItem) {
                        return (
                          (data?.labels as string[])?.[
                            tooltipItem?.[0]?.dataIndex
                          ] ?? ''
                        );
                      },
                    },
                  },
                  legend: {
                    display: true,
                  },
                },
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
