import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { ADMIN_DASHBOARD } from '../../graphql/queries';
import Dashboard from '../../components/dashboard/Dashboard';
import { DashboardData } from '../../models';
import Layout from '../../components/Layout';

export default function AppHome() {
  const [date, setDate] = React.useState(new Date());
  const [getData, { data, loading, error }] = useLazyQuery<{
    adminDashboard?: DashboardData;
  }>(ADMIN_DASHBOARD, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(
    function syncData() {
      getData({ variables: { date } });
    },
    [date]
  );
  return (
    <Layout title="Inicio">
      <Dashboard
        data={data?.adminDashboard ?? {}}
        date={date}
        setDate={setDate}
      />
    </Layout>
  );
}
