import React from 'react';
import dayjs from 'dayjs';
import { DashboardData } from '../../models';
import KPI from './KPI';
import formatMoney from '../../lib/formatMoney';
import TransactionGraph from './graphs/TransactionGraph';
import ClientsGraph from './graphs/ClientsGraph';
import AccountReceivablesGraph from './graphs/AccountReceivablesGraph';
import VolumeGraph from './graphs/VolumeGraph';

type DashboardProps = {
  data: DashboardData;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
};

export default function Dashboard({ data, date, setDate }: DashboardProps) {
  const dateString = `${dayjs(date)
    .subtract(1, 'month')
    .format('MM/YY')} - ${dayjs(date).format('MM/YY')}`;
  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-x-4 gap-y-8">
      {/* 1st row */}
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Clientes registrados"
          data={`${data?.clients?.register ?? 0} / ${
            data?.clients?.total ?? 0
          }`}
          legend="Cantidad de registrados vs total"
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title={`Puntos ganados ${dateString}`}
          data={`${formatMoney(data?.points?.earn ?? 0)}`}
          legend={`Cantidad de puntos ganados desde el ${dayjs(date)
            .subtract(1, 'month')
            .format('DD/MM/YY')} hasta el ${dayjs(date).format('DD/MM/YY')}`}
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title={`Puntos gastados ${dateString}`}
          data={`${formatMoney(data?.points?.expend ?? 0)}`}
          legend={`Cantidad de puntos gastados desde el ${dayjs(date)
            .subtract(1, 'month')
            .format('DD/MM/YY')} hasta el ${dayjs(date).format('DD/MM/YY')}`}
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Relación de puntos / USD"
          data="1,000.00"
          legend="Cantidad de puntos por cada USD"
        />
      </div>
      {/* 2nd row */}
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Clientes TM"
          data={`${data?.clients?.tm ?? 0} / ${data?.clients?.total ?? 0}`}
          legend="Cantidad de clientes TM vs total"
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Puntos ganados histórico"
          data={`${formatMoney(data?.historicPoints?.earn ?? 0)}`}
          legend="Todos los puntos ganados"
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Puntos gastados histórico"
          data={`${formatMoney(data?.historicPoints?.expend ?? 0)}`}
          legend="Todos los puntos gastados"
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        {/* <KPI
          title="Relación de puntos / USD"
          data="1,000.00"
          legend="Cantidad de puntos por cada USD"
        /> */}
      </div>
      {/* 3rd row */}
      <div className="col-span-7">
        <TransactionGraph
          date={date}
          setDate={setDate}
          transactions={data?.transactions ?? []}
        />
      </div>
      <div className="col-span-5">
        <ClientsGraph clients={data?.users ?? []} />
      </div>
      <div className="col-span-12">
        <AccountReceivablesGraph
          accountReceivables={data?.accountReceivables ?? []}
        />
      </div>
      <div className="col-span-12">
        <VolumeGraph volumes={data?.volumes ?? []} />
      </div>
    </div>
  );
}
