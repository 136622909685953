import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ChartData,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from 'chart.js';
import { VolumeGroup } from './types';

ChartJS.register(LineElement, PointElement, LinearScale, Title);
dayjs.locale('es');

interface VolumeGraphProps {
  volumes?: Array<VolumeGroup>;
}

export default function VolumeGraph({ volumes = [] }: VolumeGraphProps) {
  const [data, setData] = React.useState<ChartData<'line'>>(null);
  React.useEffect(
    function syncVolumen() {
      setData({
        labels: volumes?.map((volume) =>
          dayjs(
            new Date(
              Number(volume?._id?.year),
              Number(volume?._id?.month) - 1,
              1
            )
          ).format('MMM-YY')
        ),
        datasets: [
          {
            label: 'Premium',
            fill: false,
            borderColor: 'rgba(214, 31, 105, 1)',
            borderWidth: 2,
            data: volumes?.map((volume) => Number(volume?.premium)),
          },
          {
            label: 'Aspirational Premium',
            fill: false,
            borderColor: 'rgba(5, 122, 85, 1)',
            borderWidth: 2,
            data: volumes?.map((volume) => Number(volume?.aspirationalPremium)),
          },
          {
            label: 'Value For Money',
            fill: false,
            borderColor: 'rgba(208, 56, 1, 1)',
            borderWidth: 2,
            data: volumes?.map((volume) => Number(volume?.valueForMoney)),
          },
          {
            label: 'Volumen',
            fill: true,
            backgroundColor: 'rgba(84, 109, 193, 0.2)',
            hoverBackgroundColor: 'rgba(84, 109, 193, 1)',
            borderColor: 'rgba(84, 109, 193, 1)',
            borderWidth: 2,
            data: volumes?.map((volume) => Number(volume?.value)),
          },
        ],
      });
    },
    [volumes]
  );
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-gray-900 rounded-lg border text-slate-700 shadow-sm">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-300 mb-1 text-xs font-semibold">
              cantidad de
            </h6>
            <h2 className="text-gray-100 text-xl font-semibold">Volumen</h2>
          </div>
        </div>
      </div>
      <div className="p-4 flex-auto">
        {/* Chart */}
        <div className="relative">
          {data ? (
            <Line
              data={data}
              options={{
                responsive: true,
                hover: {
                  mode: 'nearest',
                  intersect: true,
                },
                plugins: {
                  title: {
                    display: false,
                    text: 'Volumen',
                  },
                  tooltip: {
                    mode: 'index',
                    intersect: false,
                  },
                },
                scales: {
                  x: {
                    display: true,
                    title: {
                      text: 'Mes',
                      display: true,
                    },
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    title: {
                      text: 'Volumen',
                      display: true,
                    },
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
