import React from 'react';

export interface IKPI {
  percentage: number;
  target: number;
  icon: 'up' | 'down' | 'equal';
}

export interface KPIProps {
  title: string;
  legend: string;
  data: string;
}

export default function KPI({ title, legend, data }: KPIProps) {
  return (
    <div className="rounded-lg border bg-white text-slate-700 shadow-sm">
      <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
        <h3 className="tracking-tight text-sm font-medium">{title}</h3>
      </div>
      <div className="p-6 pt-0">
        <div className="text-2xl font-bold">{data}</div>
        <p className="text-xs text-slate-400 text-ellipsis break-words overflow-hidden whitespace-nowrap cursor-pointer hover:overflow-visible hover:h-auto hover:text-clip hover:break-keep hover:whitespace-normal transition-all duration-500">
          {legend}
        </p>
      </div>
    </div>
  );
}
